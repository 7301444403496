<script>
  export let sad = false;
  export let suprised = false;
  export let nod = false;

  import { onMount, afterUpdate } from "svelte";

  import anime from "animejs/lib/anime.es.js";

  let blob;

  onMount(() => {
    anime({
      targets: blob,
      left: ["-5vw", "0", "5vw", "10vw"],
      rotate: ["3deg", "-3deg", "3deg", "-3deg"],
      // translateX: 50,
      duration: 8000,
    });
  });

  afterUpdate(() => {
    if(nod) {
      anime({
        targets: blob,
        rotate: ["3deg", "-3deg", "3deg", "-3deg"],
        delay: 1000,
        loop: 5,
        // duration: 5000,
      })
    }
  })


</script>

<div id="blob-container">
  <svg
    bind:this={blob}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 106.76 62.56"
    height="150px"
  >
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        class="cls-1 body"
        d="M37.88,4.31A16.45,16.45,0,0,1,54.74,1.77,14.7,14.7,0,0,1,63.62,15.5c-.16,3.46-.76,8.3,2.83,9.11a6.83,6.83,0,0,0,2.21,0l10.62-1.18c4.85-.54,9.79-1.08,14.58-.18s9.48,3.5,11.51,7.67.53,9.94-3.91,11.85c-3.65,1.57-7.88.29-11.76-.67a71.15,71.15,0,0,0-21.57-2c-3.53.21-7.55,1-9.26,3.92-2.1,3.59.24,9-3.09,11.63-2.28,1.82-5.69,1-8.68,1.14C42.8,57,39,59.37,35,60.74,23.18,64.74,8.69,59.33,3,48.83.41,44-.39,38.1,1.66,33a15.28,15.28,0,0,1,13-9.46c2.25-.11,4.6.3,6.71-.46s3.66-2.7,5-4.51C30.61,12.86,34.77,7,37.88,4.31Z"
      />
      {#if sad}
        <path
          class="cls-2 sad"
          d="M127.16,256.28l-3.36,6.27C126.91,263.15,129.63,258.86,127.16,256.28Z"
          transform="translate(-72 -238)"
        />
        <path
          class="cls-2 sad"
          d="M119.61,288.05A17.89,17.89,0,0,1,138,285.56"
          transform="translate(-76 -238)"
        />
      {:else if suprised}
        <path
          class="cls-2 suprised-mouth"
          d="M1501.55,1209.22a13.91,13.91,0,0,0-1.63,2.18c-3.14,5.28-2.75,12-3.08,18.2-7.86-.21-14.09-4.78-14.09-10.4s6.56-10.4,14.7-10.4A20.79,20.79,0,0,1,1501.55,1209.22Z"
          transform="translate(-676 -550) scale(0.49)"
        />
        <ellipse
          class="cls-2 suprised-eye"
          cx="7.52"
          cy="10.25"
          rx="7.02"
          ry="9.75"
          transform="translate(50 15) scale(0.4)"
        />
        <path
          class="cls-2 suprised-brow"
          d="M1474.69,1146.85a36,36,0,0,1,17-16"
          transform="translate(-990 -785) scale(0.7)"
        />
      {:else}
        <path class="cls-1 happy" d="M47.45,22.18l9.18-2.12" />
        <path
          class="cls-2 happy"
          d="M60.38,48.87A18.77,18.77,0,0,1,43.7,40.73"
        />
      {/if}
    </g>
  </svg>
</div>

<style>
  .cls-1 {
    fill: #ff8205;
  }
  .cls-1,
  .cls-2 {
    stroke: #000;
    stroke-miterlimit: 10;
  }
  .cls-2 {
    fill: #fff;
  }

  #blob-container {
    margin-top: 5rem;
    margin-bottom: 1rem;
    height: 35%;
    z-index: -999;
  }

  svg {
    height: 150px;
    width: 300px;
    position: absolute;
  }
</style>
