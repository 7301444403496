<script>
  import Blob from "./Blob.svelte";
  import { onMount } from "svelte";
  import anime from "animejs/lib/anime.es.js";

  import Typewriter from "typewriter-effect/dist/core";

  const blobStory = [
    `<span class="blob">Blob</span> är krögare.`,
    `<br> Han kan IPA och chapati.`,
    `<br> <span class="blob">Blob</span> är dock ledsen, han kan inte ta betalt i Bitcoin.`,
    ` blir partner med <span class="tomarik">BITSK,</span>`,
    ` nu kan <span class="blob">Blob</span> också <span class="tomarik">ta betalt i krypto</span>.`,
  ];

  const callToAction = `<section class="call-to-action"><p class="tomarik"> Gör som <span class="blob">Blob </span></p><a href="mailto:bitsk@viciously.co" class="tomarik call-to-action"> bli partner med <span class="tomarik-shadow">BITSK.<span></a></section>`;
  let story;

  let sad = false;
  let suprised = false;
  let nod = false;

  function happyBlob() {
    setTimeout(() => {
      sad = false;
      suprised = false;
    }, 3000);
  }

  function sadBlob() {
    setTimeout(() => {
      sad = true;
    }, 2000);
  }

  function suprisedBlob() {
    setTimeout(() => {
      sad = false;
      suprised = true;
    }, 2000);
  }

  function nodBlob() {
    nod = true;
  }

  function fancyHeader() {
    const title = document.querySelector("header h1");
    anime({
      targets: title,
      color: [
        // "#FFB900",
        "#2e4fa1",
        "#FF8C00",
        "#F7630C",
        // "#E74856",
        // "#E81123",
      ],
      easing: "linear",
      duration: 10000,
      loop: true,
    });
  }

  onMount(() => {
    const storyWrite = new Typewriter(story, {
      delay: 80,
      cursor: "",
    });

    storyWrite
      .typeString(blobStory[0])
      .typeString(blobStory[1])
      .callFunction(sadBlob)
      .typeString(blobStory[2])
      .pauseFor(2000)
      .changeDeleteSpeed(20)
      .deleteChars(50)
      .callFunction(suprisedBlob)
      .typeString(blobStory[3])
      .callFunction(happyBlob)
      .typeString(blobStory[4])
      .pauseFor(2000)
      .deleteAll(5)
      .callFunction(nodBlob)
      .typeString(callToAction)
      .callFunction(fancyHeader)
      .start();
  });
</script>

<div id="blob-story">
  <Blob {sad} {suprised} {nod} />
  <!-- <section id="header" bind:this={header} /> -->
  <section id="story" class="story-text" bind:this={story} />
</div>

<style>
  #story {
    /* min-width: 400px; */
    height: 70%;
    font-family: ff-providence-web-pro, monospace;
  }
</style>
